import * as actionTypes from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Language} from '../../domain/Language';

export const fetchLanguageStart = () => ({
  type: actionTypes.FETCH_LANGUAGE_START,
});

export const fetchLanguageSuccess =
  (language: Language) => ({
    type: actionTypes.FETCH_LANGUAGE_SUCCESS,
    language,
  });

export const fetchLanguageFail = (languageError: HttpError) => ({
  type: actionTypes.FETCH_LANGUAGE_FAIL,
  languageError,
});
