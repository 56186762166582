import * as actionTypes from './actionTypes';
import {LanguageActionTypes} from './actionTypes';
import {HttpError} from '../../config/Axios/axios-instance';
import {Language} from '../../domain/Language';

export type LanguageStateType = {
  language: Language | null,
  languageLoading: boolean,
  languageError: HttpError,
};

export type LanguageActionType = LanguageStateType & {
  type: LanguageActionTypes,
};

export const initialState: LanguageStateType = {
  language: null,
  languageLoading: true,
  languageError: null,
};

const fetchLanguageStart = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  languageLoading: true,
});

const fetchLanguageSuccess = (
  state: LanguageStateType, action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  language: action.language,
  languageLoading: false,
  languageError: null,
});

const fetchLanguageFail = (
  state: LanguageStateType, action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageError: action.languageError,
  languageLoading: false,
});

const reducer = (state = initialState, action: LanguageActionType) => {
  switch (action.type) {
  case actionTypes.FETCH_LANGUAGE_START:
    return fetchLanguageStart(state);
  case actionTypes.FETCH_LANGUAGE_SUCCESS:
    return fetchLanguageSuccess(state, action);
  case actionTypes.FETCH_LANGUAGE_FAIL:
    return fetchLanguageFail(state, action);
  default:
    return state;
  }
};

export default reducer;
